import { TOKEN_STORAGE_KEY } from 'hooks/useSession'

import { useEffect } from "react"

import LoginForm from '../components/LoginForm'

import lock from "assets/illustrations/lock.png"

const Login = () => {

  useEffect(() => {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
  }, [])


  return (
    <div className="grid md:grid-cols-3 h-screen">
      <div className="md:col-span-2 flex items-center justify-center bg-gray-50">
        <LoginForm />
      </div>
      <div className="hidden md:flex items-center justify-center bg-gray-100">
        <img src={lock} className="w-1/3" />
      </div>
    </div>
  )
}
export default Login

