
import Navigation from "components/Navigation/Navigation"


interface AppLayoutProps {
  children: React.ReactNode
}



const AppLayout = ({ children }: AppLayoutProps) => {

  return (
    <div className="bg-gray-50 h-screen md:mb-0 relative flex overflow-hidden">
      <Navigation />
      <main className="flex-1 pb-0 mb-14 md:mb-0 overflow-y-scroll">
        <div className="py-8 relative min-h-screen">
          {children}
        </div>
      </main>
    </div>
  )
}

export default AppLayout
