import { ExclamationIcon } from '@heroicons/react/solid'
import useSession from 'hooks/useSession'
import useShareholder from 'hooks/useShareholder'
import UploadPowerModal from 'pages/TransactionDetails/components/UploadPowerModal'
import { useEffect, useState } from 'react'


const UploadPowerAlert = () => {

  const [showUplaodPowerModal, setShowUploadPowerModal] = useState(false)



  const { currentUser } = useSession()

  const { shareholder } = useShareholder(currentUser?._id)


  if (shareholder?.hasPower) return null

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Para finalizar tu transacciones, debes subir un poder legalizado: {' '}
            <button onClick={() => setShowUploadPowerModal(true)} className="font-medium underline text-yellow-700 hover:text-yellow-600 focus:outline-none focus:ring-yellow-700 focus:ring-2 rounded">
              Ver las instrucciones
            </button>
          </p>
        </div>
      </div>
      <UploadPowerModal visible={showUplaodPowerModal} onClose={setShowUploadPowerModal} />
    </div>
  )

}

export default UploadPowerAlert
