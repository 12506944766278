import { InformationCircleIcon } from "@heroicons/react/solid"
import queryKeys from "config/queryKeys"
import { useQuery } from "react-query"
import { DocumentType } from "types"
import SellMotive from "types/SellMotive"
import axiosApiInstance from "utils/Axios"

interface SellMotiveSelectionProps {
  onSelectSellMotive: (id: string) => void
  enableTotalSell: (value: boolean) => void
  requiredDocuments: (document: string) => void
}

const SellMotiveSelection = ({ onSelectSellMotive, enableTotalSell, requiredDocuments }: SellMotiveSelectionProps) => {

  const { data: sellMotives } = useQuery([queryKeys.SELL_MOTIVES], () => axiosApiInstance.get<Array<SellMotive>>("/sell-motives?enabled=true").then(res => res.data))

  console.log(sellMotives)

  return (
    <ul className="space-y-4">
      {
        sellMotives?.map(sellMotive => (
          <button
            onClick={() => {
              onSelectSellMotive(sellMotive._id)
              enableTotalSell(sellMotive.totalSell)
              requiredDocuments(sellMotive.requiredDocumentTypes[0].name)
            }}
            className="relative flex w-full text-left py-3 px-5 border rounded-xl transition duration-500 ease-in-out hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-white">
            <div className="flex-1">
              <p className="font-medium text-gray-700">{sellMotive.name}</p>
              <p className="text-sm text-gray-400">{sellMotive.description}</p>
              <p className="text-gray-600 mt-2 text-sm mb-1">Documento Requerido:</p>
              <div>
                {
                  sellMotive.requiredDocumentTypes && sellMotive.requiredDocumentTypes.map((documentType: DocumentType) => (

                    <div className="text-gray-400 flex items-center ">

                      <svg className="h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                      </svg>
                      <p className="text-sm">
                        {documentType.name}
                      </p>
                    </div>
                  ))
                }
              </div>
            </div>
            {
              sellMotive.totalSell && <div className="flex items-center bg-blue-400 p-2 py-1 rounded-full text-white">
                <InformationCircleIcon className="h-4 mr-2" />
                <p className="text-xs font-medium">Venta total</p>
              </div>
            }
          </button>
        ))
      }
    </ul >
  )

}

export default SellMotiveSelection
