
import lock from "assets/illustrations/lock.png"

interface ProfileSectionProps {
  children: React.ReactNode
  title?: string
  subtitle?: string
  image?: string
}

const ProfileSection = ({ children, title, subtitle, image = lock }: ProfileSectionProps) => {

  return (
    <div className="mt-4">
      <div className="flex items-start">
        <div className="p-2 h-14 w-14 bg-gray-100 rounded-full flex items-center justify-center mr-4 flex-shrink-0">
          <img src={image} className="object-contain h-10 w-10" />
        </div>
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {subtitle}
          </p>
        </div>
      </div>
      <div className="pt-4">
        {children}
      </div>
    </div>
  )

}

export default ProfileSection
