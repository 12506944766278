import clsx from "clsx"

interface LargeButtonProps {
  disabled?: boolean;
  children?: React.ReactNode,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: "submit" | "reset"
  isLoading?: boolean
}

const LargeButton = ({ disabled = false, children, onClick, type = "submit", isLoading }: LargeButtonProps) => {

  const buttonClassnames = clsx(
    "flex justify-center items-center mt-8 shadow-xl flex-1 p-4 rounded-xl font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
    disabled
      ? "text-gray-600 bg-gray-300 shadow-none"
      : "text-white bg-blue-500 transition hover:bg-blue-600"
  )

  return (
    <button
      onClick={(e) => onClick && onClick(e)}
      disabled={disabled || isLoading}
      type={type}
      className={buttonClassnames}>
      {
        isLoading && <svg className="animate-spin delay-200 -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      }
      {children}
    </button>
  )

}

export default LargeButton
