import clsx from "clsx"
import React from "react"

export interface HomeButtonProps {
  disabled?: boolean,
  children: React.ReactNode
  variant?: "buy" | "sell"
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset"
  size?: "small" | "medium" | "large"

}

const HomeButton = ({ disabled = false, children, variant = "buy", onClick, type = "button", size = "medium" }: HomeButtonProps) => {

  const buttonClassnames = clsx(
    !disabled && "transition hover:shadow-md transform hover:-translate-y-0.5",
    "inline-flex items-center justify-center border-2 border-transparent font-medium rounded-lg text-white p-2 px-6",
    !disabled && variant === "buy" && "text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
    disabled && variant === "buy" && "text-gray-500 bg-gray-300 border-gray-400",
    variant === "sell" && "border-blue-500 text-blue-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",

  )
  return (
    <button
      type={type}
      className={buttonClassnames}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}


export default HomeButton;
