import { useMutation, useQueryClient } from "react-query";
import axiosApiInstance from "../utils/Axios";
import { useContext } from "react";
import SessionContext from "contexts/SessionContext";
import queryKeys from "config/queryKeys";


const useUploadPower = (shareholderId: string, onUploaded?: Function) => {

  const [_, setToken] = useContext(SessionContext)

  const queryClient = useQueryClient()

  const { mutate, isLoading: isUploading, error, isSuccess } = useMutation((data: FormData) => axiosApiInstance.post(`/shareholders/${shareholderId}/power`, data).then(res => res.data), {
    onSuccess: (token) => {
      // API return a new token with updated payload
      setToken(token)
      onUploaded && onUploaded()
      queryClient.invalidateQueries(queryKeys.CURRENT_SHAREHOLDER)

    }
  })

  const uploadPower = async (file: File) => {
    if (!file) return
    const formData = new FormData()
    formData.append("file", file)
    mutate(formData)
  }

  return { uploadPower, isUploading, error, isSuccess }
}

export default useUploadPower
