import Input from "components/Input"
import useSession from "hooks/useSession"
import { useForm } from "react-hook-form"
import { useEffect, useRef } from "react"
import useShareholder from "hooks/useShareholder"
import Button from "components/Button"
import ProfileSection from "./components/ProfileSection"
import secure from "assets/illustrations/secure.png"
import profile from "assets/illustrations/profile.png"
import MainLayout from "components/Layout/MainLayout"
import Select from "components/Select"

const documentTypeOptions = [
  { label: "CC" },
  { label: "CE" },
  { label: "PP" }
]

const Profile = () => {

  const { currentUser } = useSession()

  const { shareholder, updateShareholder } = useShareholder(currentUser?._id)

  const { handleSubmit, register, setValue, watch, formState: { errors } } = useForm({
    reValidateMode: "onChange"
  })
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    if (!shareholder) return
    // Identity
    setValue("firstName", shareholder.firstName)
    setValue("lastName", shareholder.lastName)
    // Auth
    setValue("professionalEmail", shareholder.professionalEmail)
    setValue("personalEmail", shareholder.personalEmail)

    // Document
    setValue("document.type", shareholder.document?.type)
    setValue("document.number", shareholder.document?.number)
    // Adress
    setValue("address.street", shareholder.address?.street)
    setValue("address.city", shareholder.address?.city)
    setValue("address.country", shareholder.address?.country)

  }, [shareholder])

  const onSubmit = (data: any) => updateShareholder(data)

  console.log("line 52", shareholder);

  return (
    <MainLayout className="space-y-4">
      <h1 className="text-2xl md:text-3xl font-bold text-gray-800">¡Hola {shareholder?.fullName}!</h1>
      <div className="bg-white shadow p-4 rounded-lg grid gap-8">
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
          <ProfileSection title="Datos personales" subtitle="Esta información permite tramitar tus transacciones" image={profile}>
            <div className="grid md:grid-cols-2 gap-4">
              <Input label="Nombres" name="firstName" ref={register({ required: true })} disabled />
              <Input label="Apellidos" name="lastName" ref={register({ required: true })} disabled />
              {/* <Select label="Tipo de documento" name="document.type" ref={register({ required: true })} options={documentTypeOptions} />
              <Input label="N° de documento" name="document.number" ref={register({ required: true })} /> */}
            </div>
          </ProfileSection>
          {/* <ProfileSection title="Dirección" subtitle="Esta información permite tramitar tus transacciones, llenala con cuidado" image={secure}>
            <div className="grid md:grid-cols-2 gap-4">
              <Input label="Dirección" name="address.street" ref={register} />
              <Input label="Ciudad" name="address.city" ref={register} />
              <Input label="País" name="address.country" ref={register} />
            </div>

          </ProfileSection> */}
          <ProfileSection title="Cuenta" subtitle="Esta es la información de tu correo profesional">
            <div className="grid gap-4">
              <Input label="Correo profesional" name="professionalEmail" ref={register} disabled />
              {/* <Input label="Correo personal" name="personalEmail" ref={register} /> */}
              {/* <div className="grid md:grid-cols-2 gap-4">
                <Input
                  label="Contraseña"
                  type="password"
                  name="password"
                  error={errors.password?.message}
                  ref={register({
                    minLength: {
                      value: 8,
                      message: "La contraseña debe tener minimo 8 caracteres"
                    }
                  }
                  )} />
                <Input
                  label="Confirmar contraseña"
                  type="password"
                  name="confirmPassword"
                  error={errors.confirmPassword?.message}
                  ref={register({
                    validate: (value) => {
                      if (value != watch('password')) {
                        return "Las contraseñas no coinciden"
                      }
                    }
                  })}
                />
              </div> */}
            </div>
          </ProfileSection>
          {/* <ProfileSection title="Notificaciones" subtitle="Elige que tipo de notificaciones quieres recibir" image={notification}>
            <div className="grid gap-4">
              <div className="flex justify-between items-center">
                <p className="block font-base text-gray-700">Informaciones sobre tus transacciones</p>
                <Toggle />
              </div>
              <div className="flex justify-between items-center">
                <p className="block font-base text-gray-700">Noticias sobre el accionariado</p>
                <Toggle />
              </div>
            </div>
          </ProfileSection> */}
          {/* <div className="flex justify-end mt-8">
            <div>
              <Button type="submit">Actualizar</Button>
            </div>
          </div> */}
        </form>
      </div >
    </MainLayout >
  )

}

export default Profile
