import Input from "components/Input"
import Button from "components/Button"
import { useForm } from "react-hook-form"


interface EventDateSelectionProps {
  onSelectEventDate: (date: Date) => void

}

const EventDateSelection = ({ onSelectEventDate }: EventDateSelectionProps) => {

  const { register, handleSubmit, errors, reset } = useForm()

  const onSubmit = async (data: any) => onSelectEventDate(data.eventDate)

  return (
    <div>
      <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Input label="Fecha del evento" name="eventDate" type="date" ref={register({ required: true })} error={errors.from} />
        <div className="flex justify-end space-x-2">
          <Button type="submit" >Aceptar</Button>
        </div>
      </form>
    </div>
  )

}

export default EventDateSelection
