import queryKeys from "config/queryKeys"
import { QueryClient, useQuery, useQueryClient } from "react-query"
import Shareholder from "types/Shareholder"
import axiosApiInstance from "utils/Axios"

const useShareholder = (id: string | undefined) => {

  const queryClient = useQueryClient()


  const { data: shareholder } = useQuery(queryKeys.CURRENT_SHAREHOLDER, () => axiosApiInstance.get<Shareholder>(`/shareholders/${id}`).then(res => res.data), { enabled: !!id })



  const updateShareholder = async (shareholder: Partial<Shareholder>) => {
    try {
      await axiosApiInstance.patch(`/shareholders/${id}`, shareholder)
    } catch (err) {
      throw err
    }
  }

  return { shareholder, updateShareholder, queryClient }
}

export default useShareholder
