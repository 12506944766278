import { DownloadIcon, InformationCircleIcon, UploadIcon, DocumentIcon } from "@heroicons/react/outline"
import Button from "../../../components/Button"
import Modal from "components/Modal/Modal"
import useUploadPower from "hooks/useUploadPower"
import { useForm } from "react-hook-form"
import useSession from "hooks/useSession"
import companyTypes from "config/companyTypes"

interface UploadPowerModalProps {
  onClose: (visible: boolean) => void
  visible: boolean
}

const UploadPowerModal = ({ visible, onClose }: UploadPowerModalProps) => {

  const { register, handleSubmit } = useForm()

  const { currentUser } = useSession()

  const { uploadPower, isUploading } = useUploadPower(`${currentUser?._id}`, () => {
    onClose(false)
  })


  const onSubmit = (data: any) => uploadPower(data.files[0])

  const handleDownloadTemplate = () => {
    if (currentUser?.company === companyTypes.DECATHLON) {
      window.open(`${process.env.REACT_APP_API_URL}/files/poder-decathlon.docx`, "_blank")
    } else {
      window.open(`${process.env.REACT_APP_API_URL}/files/poder-decoam.docx`, "_blank")
    }
  }

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="flex flex-col space-y-4 w-full">
        <h1 className="font-medium">Adjuntar poder legalizado</h1>
        <div className="rounded-md bg-blue-50 p-4 w-full">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-left text-sm text-blue-700">
              <p>Descargar el documento de poder, diligéncialo con tu información personal, imprímelo y llévalo a una notaria para legalizar.</p>
              <p>Luego debes adjuntarlo acá para ser validado y poder completar el proceso de compra</p>
            </div>
          </div>
          <div></div>
        </div>
        <div className="flex justify-center w-full">
          <Button onClick={handleDownloadTemplate}><DownloadIcon className="h-5 mr-2" />Descargar Poder</Button>
        </div>
        <div className="w-full border-t border-gray-300" />
        <form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md shadow-sm">
            <svg className="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <input
              ref={register({ required: true })}
              name="files"
              type="file"
            />
          </div>
          <div className="flex flex-col-reverse md:flex-row justify-end w-full md:space-x-2 space-y-2 md:space-y-0">
            <div className="mt-2 md:mt-0">
              <Button variant="secondary" onClick={() => onClose(false)}>Cancelar</Button>
            </div>
            <div>
              <Button isLoading={isUploading} type="submit"><UploadIcon className="h-5 mr-2" />Subir poder legalizado</Button>
            </div>
          </div>
        </form>
      </div>
    </Modal >
  )

}

export default UploadPowerModal
