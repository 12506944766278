import { notifyError, notifySuccess } from "components/ToastNotify"
import { useMutation } from "react-query"
import Document from "types/Document"
import axiosApiInstance from "utils/Axios"
import { base64ToDocument } from "utils/functions"

interface DocumentListItemProps {
  document: Document
}

const DocumentListItem = ({ document }: DocumentListItemProps) => {

  const { mutate: downloadDocument, isLoading } = useMutation((documentId: string) => axiosApiInstance.get(`/transactions/files/${documentId}`).then(res => res.data), {
    onSuccess: (reportURL, documentId) => {
      base64ToDocument(reportURL, documentId);
      notifySuccess("Documento descargado")
    },
    onError: () => {
      notifyError("No se a podido descargar")
    }
  })

  const download = (document: string) => {
    downloadDocument(document);
  }

  return (
    <li className="pl-3 pr-4 py-3 flex flex-col md:flex-row items-center justify-between text-sm">
      <div className="flex">
        {/* <!-- Heroicon name: solid/paper-clip --> */}
        <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
        </svg>
        <div className="flex flex-col w-full text-black">
          <span className="ml-2 flex-1 truncate">
            {document.documentType.name}
          </span>
          <span className="ml-2 flex-1 text-gray-400">
            {document.name}
          </span>
        </div>
      </div>
      <div className="ml-4 flex-shrink-0 mt-2">
        <button type="button" onClick={() => download(document.url)} className="font-medium text-indigo-600 hover:text-indigo-500">Descargar</button>
        {/* <a href={document.url} target="_blank" className="font-medium text-indigo-600 hover:text-indigo-500">
          Descargar
        </a> */}
      </div>
    </li>
  )

}

export default DocumentListItem
