import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";

const BlurSensitiveDataContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([true, () => { }])
export default BlurSensitiveDataContext

interface BlurSensitiveDataContextProviderProps {
  children: React.ReactElement
}

const SHOULD_BLUR_LOCAL_STORAGE_KEY = "shouldBlur"

export const BlurSensitiveDataContextProvider = ({ children }: BlurSensitiveDataContextProviderProps) => {

  const [shouldBlur, setShouldBlur] = useState<boolean>(false)

  useEffect(() => {
    setShouldBlur(localStorage.getItem(SHOULD_BLUR_LOCAL_STORAGE_KEY) === "true")
  }, [])

  useEffect(() => {
    localStorage.setItem(SHOULD_BLUR_LOCAL_STORAGE_KEY, shouldBlur.toString())
  }, [shouldBlur])

  return (
    <BlurSensitiveDataContext.Provider value={[shouldBlur, setShouldBlur]}>
      {children}
    </BlurSensitiveDataContext.Provider>
  )
}
