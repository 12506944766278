import mutationKeys from "config/mutationKeys";
import queryKeys from "config/queryKeys";
import sellMotives from "config/sellMotives";
import transactionTypes from "config/transactionTypes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Transaction } from "types";
import ShareValue from "types/ShareValue";
import axiosApiInstance from "utils/Axios";

interface SellingSimulation {
  maxAmount: number
  shareValue: ShareValue
  unlockedAmount: number
  lockedSharesAmount: number
}

const useSellShare = (shareholderId: string | undefined) => {

  const { data: simulation, isLoading: isLoadingSimulation } = useQuery([queryKeys.SELLING_SIMULATION], () => axiosApiInstance.get<SellingSimulation>(`/shareholders/${shareholderId}/simulations/selling`).then(res => res.data), { enabled: !!shareholderId })

  const queryClient = useQueryClient()

  // This logic could be share by BUY and SELL hooks with another hook
  const { mutate, isLoading, isSuccess, error, reset, data: transaction } = useMutation((transaction: Partial<Transaction>) => axiosApiInstance.post<Transaction>('/transactions', transaction).then(res => res.data), {
    mutationKey: mutationKeys.CREATE_SELL_TRANSACTION,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.PORTAFOLIO, queryKeys.SELLING_SIMULATION, queryKeys.BUYING_SIMULATION, queryKeys.TRANSACTIONS])
    }
  })

  const sell = async (amount: number, sellMotiveId: string, eventDate: Date | undefined) => mutate({
    type: transactionTypes.DEBIT,
    amount,
    sellMotive: sellMotiveId === sellMotives.UNLOCKED ? undefined : sellMotiveId,
    eventDate: eventDate

  })

  return { simulation, sell, isLoadingSimulation, isLoading, error, isSuccess, transaction }

}

export default useSellShare
