import queryKeys from "config/queryKeys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DocumentType } from "types";
import axiosApiInstance from "utils/Axios";

interface FileUpload {
  file: File
  documentTypeId: string
}

const useUploadDocumentForTransaction = (id: string) => {

  const { isLoading, data: documentTypes } = useQuery([queryKeys.DOCUMENT_TYPES], () => axiosApiInstance.get<Array<DocumentType>>("/document-types/").then(res => res.data))

  const queryClient = useQueryClient()

  const config = {
    headers: {
      "content-Type": "multipart/form-data"
    }
  }

  const { mutate, isLoading: isUploading, error, isSuccess } = useMutation((data: FileUpload) => {
    const formData = new FormData();
    formData.set("file", data.file)
    return axiosApiInstance.post(`/transactions/${id}/documents/${data.documentTypeId}`, formData, config).then(res => res.data)
  }, {
    onSuccess: () => {
      // console.log(id);
      queryClient.invalidateQueries(id)
    }
  })

  const uploadDocument = async (file: File, documentTypeId: string) => mutate({ file, documentTypeId })

  return { documentTypes, uploadDocument, isLoading, isUploading, error, isSuccess }
}

export default useUploadDocumentForTransaction
