import { useContext, useState } from 'react'
import sessionContext from 'contexts/SessionContext'
import { useHistory } from 'react-router-dom'
import routes from 'config/routes'
import jwt from "jsonwebtoken"
import axiosApiInstance from "utils/Axios"
import { TokenUser } from "types/Shareholder"

export interface UserRegister {
  email: string
  password: string
}

export interface Token {
  _id: string
  fullName: string
  hasPower: boolean
}


export const TOKEN_STORAGE_KEY = "token"

const useSession = () => {

  const [token, setToken] = useContext(sessionContext)

  const getDecodedToken = () => {
    if (!token) return undefined
    return jwt.decode(token) as Token
  }

  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const login = async (email: string, password: string) => {
    setIsLoggingIn(true)
    try {
      const { token } = await axiosApiInstance.post("/auth/login", { email, password }).then(res => res.data)
      setToken(token)
      setIsLoggingIn(false)
    }
    catch (err) {
      setIsLoggingIn(false)
      console.log(err)
      throw err
    }
  }

  const loginWithSSO = () => {
    if (!process.env.REACT_APP_SSO_LOGIN_URL) return
    window.location.href = process.env.REACT_APP_SSO_LOGIN_URL
    //window.location.href = `https://idpdecathlon.oxylane.com/as/authorization.oauth2?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&state=${process.env.REACT_APP_URL}&redirect_uri=${process.env.REACT_APP_API_URL}/auth/dashboard/sso&response_type=code&scope=openid%20profile`
  }

  const logout = () => setToken(undefined)

  const currentUser = getDecodedToken() as TokenUser | undefined

  return { currentUser, logout, login, loginWithSSO, isLoggingIn }

}

export default useSession


