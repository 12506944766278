import { LogoutIcon, TrendingUpIcon, UserCircleIcon, ExternalLinkIcon } from "@heroicons/react/outline"
import logo from "assets/Shareholding_logo.png"
import clsx from "clsx"
import routes from "config/routes"
import useSession from "hooks/useSession"
import { Link, useLocation } from "react-router-dom"



const Navigation = () => {

  const menuItemClassnames = clsx(
    "bg-gray-50 rounded-full p-3 text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
  )

  const { currentUser, logout } = useSession()

  const location = useLocation()

  return (
    <div className="flex overflow-hidden">
      <div className="z-10 md:hidden flex h-14 fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t">
        <div className="flex-1 flex items-center justify-around">
          <Link to={routes.home} className={menuItemClassnames}><TrendingUpIcon className="h-5 w-5" /></Link>
          <Link to={routes.profile} className={menuItemClassnames}><UserCircleIcon className="h-5 w-5" /></Link>
        </div>
      </div>
      <div className="hidden h-screen md:flex md:flex-shrink-0  bg-white shadow-xl border-r ">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center justify-center flex-shrink-0 px-4">
                <img
                  className="w-1/3"
                  src={logo}
                  alt="Logo"
                />
              </div>
              <div className="my-5 space-y-1 px-8 ">
                <h3 className="font-semibold text-medium text-gray-800">{currentUser?.fullName}</h3>
                <p className="text-gray-500 text-sm font-medium">{currentUser?.company.toUpperCase()}</p>
              </div>
              <nav className="mt-5 flex-1 space-y-1">
                <Link
                  key={routes.home}
                  to={routes.home}
                  className={clsx(
                    location.pathname === "/" ? 'text-blue-500 border-blue-500' : 'border-transparent text-gray-400 hover:text-blue-500',
                    'group flex items-center px-2 py-4 text-sm font-semibold border-l-4 pl-8'
                  )}
                >
                  <TrendingUpIcon
                    className={clsx(
                      location.pathname === "/" ? 'text-blue-500' : 'text-gray-400 group-hover:text-blue-500',
                      'mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  Portafolio
                </Link>
                <Link
                  key={routes.profile}
                  to={routes.profile}
                  className={clsx(
                    location.pathname.includes(routes.profile) ? 'text-blue-500 border-blue-500' : 'border-transparent text-gray-400 hover:text-blue-500',
                    'group flex items-center px-2 py-4 text-sm font-semibold border-l-4 pl-8'
                  )}
                >
                  <UserCircleIcon
                    className={clsx(
                      location.pathname.includes(routes.profile) ? 'text-blue-500' : 'text-gray-400 group-hover:text-blue-500',
                      'mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  Perfil
                </Link>
                <a
                  key="offical_plateform"
                  href="https://share.decathlon.com/"
                  target="_blank"
                  className={clsx(
                    'border-transparent text-gray-400 hover:text-blue-500',
                    'group flex items-center px-2 py-4 text-sm font-semibold border-l-4 pl-8'
                  )}
                >
                  <ExternalLinkIcon
                    className={clsx(
                      'text-gray-400 group-hover:text-blue-500',
                      'mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  Plataforma oficial
                </a>
              </nav>
            </div>
            <div className="flex-shrink-0 flex p-4">
              <button onClick={logout} className="flex-shrink-0 w-full group block">
                <div className="pl-8 flex items-center text-gray-400 hover:text-red-400">
                  <LogoutIcon className="mr-3 h-6 w-6" />
                  <p className="text-sm font-semibold">logout</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Navigation
