enum queryKeys {
  CURRENT_SHAREHOLDER = "currentShareholder",
  DOCUMENT_TYPES = "documentTypes",
  TRANSACTIONS = "transactions",
  SHARE_VALUES = "shareValues",
  BUYING_PERIODS = "buyingPeriods",
  DASHBOARD_USERS = "dashboardUsers",
  ANNOUNCEMENTS = "announcements",
  SELL_MOTIVES = "sellMotives",
  SHAREHOLDERS = "shareholders",
  LAST_ANNOUNCEMENT = "last_announcement",
  PORTAFOLIO = "portafolio",
  BUYING_SIMULATION = "buyingSimulation",
  SELLING_SIMULATION = "sellingSimulation",
  BAE = "bae",
}


export default queryKeys
