
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import PrivateRoute from 'components/Routing/PrivateRoute'
import { Home } from '../../pages/Home/Home'
import Login from '../../pages/Auth/Login'
import Buy from '../../pages/Buy/Buy'
import SellingForm from '../../pages/SellingForm/SellingForm'
import { TransactionDetails } from 'pages/TransactionDetails/TransactionDetails';
import AppLayout from 'components/Layout/AppLayout';
import routes from 'config/routes'
import useSession from 'hooks/useSession'
import Profile from 'pages/Profile/Profile'
import AcceptBAE from 'pages/AcceptBAE/AcceptBAE'

import {
  ArrowRightIcon,
  ArrowLeftIcon,
  RefreshIcon,

} from '@heroicons/react/outline'
import { InMaintenance } from 'pages/InMaintenance/InMaintenance'


const features = [
  { name: 'Compra de acciones', icon: ArrowRightIcon, description: "" },
  { name: 'Venta de acciones', icon: ArrowLeftIcon, description: "" },
  { name: 'Seguimiento de tus transacciones', icon: RefreshIcon, description: "" },
]

function AppRouter() {

  // return <InMaintenance />

  const { currentUser } = useSession()

  if (!currentUser) return <Login />

  return (
    <Router>
      <Switch>
        <Route exact path={routes.login} component={Login} />
        <AppLayout>
          <PrivateRoute exact path={routes.home} component={Home} />
          <PrivateRoute exact path={routes.buy} component={Buy} />
          <PrivateRoute exact path={routes.bae} component={AcceptBAE} />
          <PrivateRoute exact path={routes.sell} component={SellingForm} />
          <PrivateRoute exact path={routes.transaction + "/:id"} component={TransactionDetails} />
          <PrivateRoute exact path={routes.profile} component={Profile} />
          <Redirect to={routes.home} />
        </AppLayout>
      </Switch>
    </Router >
  )
}
export default AppRouter
