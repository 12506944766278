import BlurSensitiveDataContext from "contexts/BlurSensitiveDataContext"
import { useContext } from "react"

const useBlurSensitiveData = () => {
  const [shouldBlur, setShouldBlur] = useContext(BlurSensitiveDataContext)

  const blurOrUnblur = () => setShouldBlur(shouldBlur => !shouldBlur)

  return { shouldBlur, blurOrUnblur }
}

export default useBlurSensitiveData
