import clsx from "clsx"

interface MainLayoutProps {
  children: React.ReactNode
  className?: string
}

const MainLayout = ({ children, className }: MainLayoutProps) => {

  const contentClassNames = clsx(
    "max-w-3xl mx-auto oveflow-hidden",
    className
  )

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
      <div className={contentClassNames}>
        {children}
      </div>
    </div >
  )

}

export default MainLayout
