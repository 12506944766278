
interface CardProps {
  title?: string
  subtitle?: string
  children?: React.ReactNode

}

const Card = ({ title, subtitle, children }: CardProps) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {subtitle}
        </p>
      </div>
      <div className="px-4 py-5 sm:p-6">
        {children}
      </div>
    </div>
  )

}

export default Card

