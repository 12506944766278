import toast from 'react-hot-toast';

export const notifySuccess = (message: string) => toast.success(message,{
    duration: 3500,
    style: {
        background: "#212121",
        color: "white"
    }
})

export const notifyError = (message: string) => toast.error(message,{
    duration: 3500,
    style: {
        background: "#212121",
        color: "white"
    }
});