import clsx from "clsx"
import { Transaction } from "types";
import {
  Link,
} from 'react-router-dom'
import useBlurSensitiveData from "hooks/useBlurSensitiveData";
import { formatDateToDateString, formatNumberToEuroString } from "utils";
import transactionStates from "config/transactionStates";
import routes from "config/routes";
import transactionTypes from "config/transactionTypes";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon, ArrowRightIcon, GiftIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import TransactionStateBadge from "./TransactionStateBadge";
import { type } from "os";


interface TransactionListItemProps {
  transaction: Transaction
}

const getTransactionIcon = (transactionType: string) => {
  const iconClassnames = "h-4"
  switch (transactionType) {
    case transactionTypes.CREDIT:
      return <ArrowRightIcon className={iconClassnames} />
    case transactionTypes.DEBIT:
      return <ArrowLeftIcon className={iconClassnames} />
    case transactionTypes.BAE:
      return <GiftIcon className={iconClassnames} />
    case transactionTypes.TOP_UP:
      return <GiftIcon className={iconClassnames} />
    default:
      return <QuestionMarkCircleIcon className={iconClassnames} />
  }
}

const getTransactionDescription = (transaction: Transaction): string => {
  let description = ""
  switch (transaction.type) {
    case transactionTypes.CREDIT:
      description += "Compraste "
      break
    case transactionTypes.DEBIT:
      description += "Vendiste "
      break
    case transactionTypes.BAE:
      description += "Recibiste "
      break
    case transactionTypes.TOP_UP:
      description += "Recibiste "
      break
    case transactionTypes.INITIAL:
      description += "Actualmente tienes "
      break
  }
  description += `${transaction.amount} acciones`
  switch (transaction.type) {
    case transactionTypes.DEBIT:
      description += `: ${transaction.sellMotive?.name ?? " Venta libre"}`
      break
    case transactionTypes.BAE:
      description += ` por BAE`
      break
    case transactionTypes.TOP_UP:
      description += " por TOP UP"
      break
  }
  return description
}

const TransactionListItem = ({ transaction }: TransactionListItemProps) => {

  const { shouldBlur } = useBlurSensitiveData()
  const { t } = useTranslation();

  const amoutLabelClassNames = clsx(
    "font-medium p-2 min-w-0 md:mr-0",
    (transaction.type === transactionTypes.CREDIT || transaction.type === transactionTypes.BAE || transaction.type === transactionTypes.INITIAL) && "text-green-600 bg-green-50 rounded-md",
    transaction.type === transactionTypes.DEBIT && "text-gray-800",
    (transaction.state === transactionStates.REJECTED || transaction.state === transactionStates.CANCELLED) && "line-through",
    shouldBlur && "blurred"
  )

  const icon = getTransactionIcon(transaction.type)
  const description = getTransactionDescription(transaction)

  return (
    <Link to={`${routes.transaction}/${transaction._id}`}>
      <div className="px-4 py-4 sm:px-6 border-l-4 border-white hover:border-blue-500">
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div className="text-gray-600 bg-gray-200 p-2 rounded-full mr-3 flex-shrink-0">
              {icon}
            </div>
            <div className="grid items-start min-w-0 flex-1 text-sm md:text-base">
              <p className={`truncate font-medium ${(transaction.state === transactionStates.REJECTED || transaction.state === transactionStates.CANCELLED) && "line-through"} ${shouldBlur && "blurred"}`}>{description}</p>
              <p className="text-gray-600 mb-2">{formatDateToDateString(transaction.createdAt)}</p>
              <TransactionStateBadge state={transaction.state} />
            </div>
          </div>
          <p className={amoutLabelClassNames}>
            {transaction.type === transactionTypes.DEBIT && "-"}{formatNumberToEuroString(transaction.amountEuro)}
          </p>

        </div>
      </div>
    </Link >
  )

}

export default TransactionListItem
