import useSession from "hooks/useSession";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import axiosApiInstance from "utils/Axios";
import PortafolioType from "../../../types/Portafolio";
import HomeButton from "./HomeButton";
import routes from "config/routes";
import useBlurSensitiveData from "hooks/useBlurSensitiveData";
import { formatNumberToEuroString } from "utils";
import queryKeys from "config/queryKeys";

export interface PortafolioProps {
  isValidDate: boolean | undefined;
}

const Portafolio = ({ isValidDate }: PortafolioProps) => {
  const { shouldBlur } = useBlurSensitiveData();
  const { blurOrUnblur } = useBlurSensitiveData();
  const history = useHistory();
  const { currentUser } = useSession();

  const { data: portafolio } = useQuery(
    [queryKeys.PORTAFOLIO],
    () =>
      axiosApiInstance
        .get<PortafolioType>(`/shareholders/${currentUser?._id}/portafolio`)
        .then((res) => res.data),
    { enabled: !!currentUser }
  );

  return (
    <div className="flex flex-col md:flex-row justify-between">
      <div className="space-y-2">
        <h1 className="font-medium text-blue-500 md:hidden">
          Bienvenido {currentUser?.fullName}
        </h1>

        <h3
          className={`font-bold text-4xl text-gray-900 ${
            shouldBlur && "blurred"
          }`}
        >
          {portafolio &&
            formatNumberToEuroString(portafolio?.currentTotalSharesValue)}
        </h3>

        <p
          className={`inline-flex items-baseline px-2.5 py-2.5 rounded-md text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0 ${
            shouldBlur && "blurred"
          }`}
        >
          {portafolio?.totalSharesAmount} acciones
        </p>
        <button className="flex space-x-2 items-center text-gray-400 focus:outline-none">
          {shouldBlur ? (
            <svg
              className="h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fillRule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              className="h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clipRule="evenodd"
              />
              <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
            </svg>
          )}

          <span className="text-xs" onClick={blurOrUnblur}>
            {shouldBlur ? "Mostrar" : "Esconder"}
          </span>
        </button>
      </div>
      <div className="flex items-start m-auto py-6 md:py-0 md:mx-0 md:my-0 flex-row space-x-2 md:jutify-end">
        <HomeButton
          onClick={() => history.push(routes.buy)}
          variant="buy"
          disabled={!isValidDate}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          Comprar
        </HomeButton>
        <HomeButton onClick={() => history.push(routes.sell)} variant="sell">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          Vender
        </HomeButton>
      </div>
    </div>
  );
};
export default Portafolio;
