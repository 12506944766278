
import Button from "components/Button"
import { useHistory } from "react-router-dom"
import routes from "../../../config/routes"
interface RulesProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Rules = ({ onClick }: RulesProps) => {
  const history = useHistory()

  return (
    <div className="py-8">
      <a className="underline" href={`${process.env.REACT_APP_API_URL}/files/reglas.pdf`} target="_blank">Reglas y Regulaciones </a>
      <div className="flex flex-row justify-evenly space-x-12 my-8">
        <Button onClick={(prev) => history.push(routes.home)} size="small" variant="secondary">No Acepto</Button>
        <Button onClick={onClick} size="small" variant="primary">Acepto</Button>
      </div>
    </div>
  )
}

export default Rules
