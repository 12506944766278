export const formatNumberToEuroString = (value: number) => new Intl.NumberFormat('es-ES',
  { style: 'currency', currency: 'EUR' }).format(value)

export const formatNumberToPesoString = (value: number) => new Intl.NumberFormat('es-ES',
  { style: 'currency', currency: 'COP' }).format(value)

export const formatDateToDateString = (date: string | Date) => new Intl.DateTimeFormat('es-CO', {
  year: "numeric",
  month: "long",
  day: "2-digit"
}).format(new Date(date))
