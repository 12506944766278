import { Controller, useForm } from "react-hook-form"
import RadioGroup from "components/RadioGroup/RadioGroup"

const fullSellOptions = [
  {
    name: "Venta Anticipada",
    description: "Venta de acciones que puedes solicitar por causales específicas antes del cumplimiento del periodo de cinco años de bloqueo de las acciones",
    id: "anticipated",
    disabled: false
  },
  {
    name: "Venta libre",
    description: "Venta de acciones que el accionista puede solicitar libremente después del cumplimiento del periodo de cinco años de bloqueo de las acciones",
    id: "unlocked",
    disabled: false
  }
]

const onlyAnticipatedSellOptions = [
  {
    name: "Venta Anticipada",
    description: "Venta de acciones que puedes solicitar por causales específicas antes del cumplimiento del periodo de cinco años de bloqueo de las acciones",
    id: "anticipated",
    disabled: false
  },
  {
    name: "Venta libre",
    description: "En este momento no tienes acciones desbloqueadas",
    id: "unlocked",
    disabled: true
  }
]

const onlyFreeSellOptions = [
  {
    name: "Venta Anticipada",
    description: "En este momento no tienes acciones que hayan sido adquiridas durante los últimos 5 años, pero puedes utilizar la opción de venta libre en este caso",
    id: "anticipated",
    disabled: true
  },
  {
    name: "Venta libre",
    description: "Venta de acciones que el accionista puede solicitar libremente después del cumplimiento del periodo de cinco años de bloqueo de las acciones",
    id: "unlocked",
    disabled: false
  }
]

const disabledOptions = [
  {
    name: "Venta Anticipada",
    description: "En este momento no tines acciones que hayan sido adquiridas durante los últimos 5 años",
    id: "anticipated",
    disabled: true
  },
  {
    name: "Venta libre",
    description: "En este momento no tienes acciones desbloqueadas",
    id: "unlocked",
    disabled: true
  }
]


interface SellTypeSelectionProps {
  onSelectSellType: (id: string) => void
  unlockedSharesAmount: number | undefined
  lockedSharesAmount: number | undefined
}

const SellTypeSelection = ({ onSelectSellType, unlockedSharesAmount, lockedSharesAmount }: SellTypeSelectionProps) => {

  const { control } = useForm({
    reValidateMode: "onBlur"
  })


  const getSellOptions = (unlockedSharesAmount: number | undefined, lockedSharesAmount: number | undefined) => {
    if (unlockedSharesAmount && unlockedSharesAmount > 0) {
      if (lockedSharesAmount && lockedSharesAmount > 0) {
        return fullSellOptions
      }
      else {
        return onlyFreeSellOptions
      }
    }
    else {
      if (lockedSharesAmount && lockedSharesAmount > 0) {
        return onlyAnticipatedSellOptions
      }
      else {
        return disabledOptions
      }
    }
  }

  const sellOptions = getSellOptions(unlockedSharesAmount, lockedSharesAmount)


  return (
    <form className="grid gap-4" >
      <Controller
        control={control}
        name="sellType"
        render={
          ({ value }) =>
            <RadioGroup
              label="Elige un tipo de venta"
              options={sellOptions}
              value={value}
              onChange={onSelectSellType}
            />
        }
      />
    </form>
  )
}

export default SellTypeSelection
