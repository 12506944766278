
import { Transaction } from "types"
import TransactionListItem from "./TransactionListItem"

export interface TransactionListProps {
  transactions: Array<Transaction> | undefined
}

const TransactionList = ({ transactions }: TransactionListProps) => {

  if (transactions?.length === 0) return <p className='text-gray-500 bg-transparent'>Todavía no tienes transacciones</p>

  return (
    <ul className='space-y-0 bg-white rounded-lg overflow-hidden'>
      {
        transactions && transactions.map((transaction) => <TransactionListItem transaction={transaction} key={transaction._id} />)
      }
    </ul>
  )
}

export default TransactionList;


