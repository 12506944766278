import { useQuery } from 'react-query'
import TransactionList from './components/TransactionList/TransactionList'
import AnnouncementCard from './components/AnnouncementCard'
import Portafolio from './components/Portafolio'
import BAE from './components/Bae'
import { Transaction } from 'types'
import axiosApiInstance from 'utils/Axios'
import useSession from 'hooks/useSession'
import queryKeys from 'config/queryKeys'
import MainLayout from 'components/Layout/MainLayout'
import UploadPowerAlert from 'components/UploadPowerAlert'

export function Home() {

  const {
    data: isValidDate,
  } = useQuery([], () => axiosApiInstance.get<boolean>("/buying-periods/valid-buying-period").then(res => res.data))

  const { currentUser } = useSession()

  const {
    data: transactions
  } = useQuery([queryKeys.TRANSACTIONS], () => axiosApiInstance.get<Array<Transaction>>(`/shareholders/${currentUser?._id}/transactions`).then(res => res.data), { enabled: !!currentUser })

  return (
    <MainLayout>
      <div className="space-y-4">
        <Portafolio isValidDate={isValidDate} />
        <div className="flex flex-col space-y-4">
          <AnnouncementCard />
          <BAE />
          <UploadPowerAlert />
        </div>
        <p className="mb-4 text-gray-800 font-semibold text-xl">Transacciones</p>
        <TransactionList transactions={transactions} />
      </div>
    </MainLayout >

  )
}
