import axios from "axios";
import { TOKEN_STORAGE_KEY } from "hooks/useSession";
const axiosApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`,
});

//Request interceptor API Calls

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
