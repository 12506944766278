import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom'
import routes from 'config/routes'
import useSession from 'hooks/useSession'
import React from 'react'

interface PrivateRouteProps {
  exact: boolean
  path: string
  component: React.FunctionComponent
}

const PrivateRoute = ({ exact, path, component: Component }: PrivateRouteProps) => {
  const { currentUser } = useSession()
  return (
    <Route
      exact={exact}
      path={path}
      render={() =>
        currentUser ? <Component /> : <Redirect to={routes.login} />
      }
    />
  )
}
export default PrivateRoute
