import { Transition } from "@headlessui/react"
import planeImage from "assets/illustrations/plane.png"
import queryKeys from "config/queryKeys"
import { useQuery } from "react-query"
import { Announcement } from "types"
import axiosApiInstance from "utils/Axios"

const AnnouncementCard = () => {

  const { data: announcement } = useQuery<Announcement>(queryKeys.LAST_ANNOUNCEMENT, () => axiosApiInstance.get(`announcements/last`).then(res => res.data))

  if (!announcement) return null

  return (
    <Transition
      show={!!announcement}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0 rotate-[-120deg] scale-50"
      enterTo="opacity-100 rotate-0 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 rotate-0 scale-100 "
      leaveTo="opacity-0 scale-95 "
    >
      <div className="rounded-xl bg-white transition hover:shadow-lg p-4 border">
        <div className="flex flex-col items-center md:flex-row">
          <img src={planeImage} className="h-40 w-40 md:h-20 md:w-auto object-contain mr-2" />
          <div className="flex flex-col justify-start flex-1">
            <p className="font-medium">{announcement?.title}</p>
            <p className="mt-2">{announcement?.text}</p>
          </div>
        </div>
      </div>
    </Transition>
  )

}

export default AnnouncementCard
