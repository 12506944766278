import mutationKeys from "config/mutationKeys"
import queryKeys from "config/queryKeys"
import transactionTypes from "config/transactionTypes"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Transaction } from "types"
import ShareValue from "types/ShareValue"
import axiosApiInstance from "utils/Axios"

export interface BuyingSimulation {
  maxAmount: number
  monthlySalary: number
  anualSalary: number
  isValidBuyingDate: boolean
  hasAlreadyBought: boolean
  shareValue: ShareValue
}

const useBuyShares = (shareholderId: string | undefined) => {

  const { data: simulation, isLoading: isLoadingSimulation } = useQuery([queryKeys.BUYING_SIMULATION], () => axiosApiInstance.get<BuyingSimulation>(`/shareholders/${shareholderId}/simulations/buying`).then(res => res.data), { enabled: !!shareholderId })

  const queryClient = useQueryClient()

  // This logic could be share by BUY and SELL hooks with another hook
  const { mutate, isLoading, error, isSuccess, data: transaction } = useMutation((transaction: Partial<Transaction>) => axiosApiInstance.post<Transaction>('/transactions', transaction).then(res => res.data), {
    mutationKey: mutationKeys.CREATE_BUY_TRANSACTION,
    onSuccess: () => queryClient.invalidateQueries([queryKeys.PORTAFOLIO, queryKeys.SELLING_SIMULATION, queryKeys.BUYING_SIMULATION, queryKeys.TRANSACTIONS])
  })

  const buy = async (amount: number) => mutate({
    type: transactionTypes.CREDIT,
    amount
  })

  return { buy, simulation, isLoadingSimulation, isLoading, error, isSuccess, transaction }

}

export default useBuyShares
