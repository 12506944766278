import clsx from "clsx"

import LargeButton from "components/LargeButton"
import queryKeys from "config/queryKeys"
import sellTypes from "config/sellTypes"

import useSession from "hooks/useSession"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { Portafolio } from "types"
import ShareValue from "types/ShareValue"
import { formatNumberToEuroString, formatNumberToPesoString } from "utils"
import axiosApiInstance from "utils/Axios"
import { ExclamationIcon } from '@heroicons/react/solid'

interface SellAmountSelectionProps {
  onChooseAmount: (amount: number | undefined) => void
  shareValue: ShareValue | undefined
  amount: number | undefined
  sellType: string | undefined
  isTotalSell: boolean | undefined
}

const SellAmountSelection = ({ onChooseAmount, shareValue, amount, sellType, isTotalSell }: SellAmountSelectionProps) => {

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  })

  useEffect(() => {
    setValue("amount", amount)
  }, [amount])

  const onSubmit = (data: any) => isTotalSell ? onChooseAmount(portafolio?.totalSharesAmount) : onChooseAmount(data.amount)

  const { currentUser } = useSession()

  const { data: portafolio, } = useQuery([queryKeys.PORTAFOLIO], () => axiosApiInstance.get<Portafolio>(`/shareholders/${currentUser?._id}/portafolio`).then(res => res.data), { enabled: !!currentUser })

  const inputClassNames = clsx(
    "w-full text-4xl font-semibold focus:outline-none focus:ring-0 border-none focus:border-none bg-transparent rounded-lg p-0",
    errors.amount ? "text-red-500" : "text-green-500"
  )


  if (isTotalSell) {
    return (
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                El causal elegido exige la venta de la totalidad de tus <strong>{portafolio?.totalSharesAmount}</strong> acciones
              </p>
            </div>
          </div>
        </div>
        <div className="flex sticky bottom-5 md:bottom-10">
          <LargeButton disabled={!isTotalSell}>Aceptar</LargeButton>
        </div>
      </form >
    )

  }
  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid md:grid-cols-2 gap-4">
        <div className="">
          <input
            ref={register({
              required: true,
              max: sellType === "anticipated" ? portafolio?.lockedSharesAmount : portafolio?.unlockedSharesAmount,
              min: 1
            })}
            name="amount"
            placeholder="0"
            className={inputClassNames} type="number"
          />
          {
            sellType === "anticipated" ?
              <p className={`${errors.amount ? "text-red-500" : "text-gray-400"} `}>Tienes {portafolio?.lockedSharesAmount} acciones</p>
              :
              <p className={`${errors.amount ? "text-red-500" : "text-gray-400"}`}>Tienes {portafolio?.unlockedSharesAmount} acciones desbloqueadas</p>
          }
        </div>
        <div>
          <p className="md:text-right mt-4 md:mt-0 md:pl-4 text-4xl text-gray-400 font-semibold focus:outline-none focus:ring-0 border-none focus:border-none bg-transparent rounded-lg p-0">
            {formatNumberToEuroString(watch("amount") * (shareValue?.valueEuro ?? 0))}
          </p>
          <p className={`md:text-right text-gray-400 `}>{formatNumberToPesoString(watch("amount") * (shareValue?.valueCOP ?? 0))} estimados</p>
        </div>

      </div>
      <div className="flex sticky bottom-5 md:bottom-10">
        <LargeButton disabled={errors.amount || !watch("amount")}>Seguir</LargeButton>
      </div>
    </form >
  )

}

export default SellAmountSelection
