
interface FormStepProps {
  children?: React.ReactNode
  title: string
  subtitle?: string
  current?: boolean
  onBack?: () => void
}


const FormStep = ({ children, title, subtitle, current = true, onBack }: FormStepProps) => {

  if (!current) return null

  return (
    <div className="flex lg:max-w-7xl lg:mx-auto mx-4 md:my-8 h-full">
      <div className="max-w-3xl flex flex-col md:flex-row items-start md:ml-14">
        <div className="md:sticky md:top-16">
          {
            onBack ? <button
              onClick={onBack}
              className="rounded-2xl md:border border-gray-300 md:p-3 flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg className="h-6 w-6 md:h-8 md:w-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
              : <div className="h-6 w-6 md:h-8 md:w-8  md:m-3" />
          }


        </div>
        <div className="md:ml-24">
          <h1 className="text-3xl font-bold">{title}</h1>
          <h3 className=" text-gray-600 mt-2">{subtitle}</h3>
          <div className="mt-8 space-y-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  )

}

export default FormStep
