import LargeButton from "../../../components/LargeButton"
import { useHistory } from "react-router-dom"
import routes from "../../../config/routes"
import { Transaction } from "types"
import companyTypes from "config/companyTypes"
import useSession from "hooks/useSession"

interface PaymentInstructionsProps {
  transaction: Transaction | undefined
}

const PaymentInstructions = ({ transaction }: PaymentInstructionsProps) => {
  const history = useHistory()
  const { currentUser } = useSession()

  return (
    <div className="bg-transparent">
      { currentUser?.company === companyTypes.DECATHLON ?
        < div className="max-w-2xl mx-auto text-center py-8 px-4 sm:py-10 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold sm:text-4xl">
            <span className="block">DECATHLON COLOMBIA S.A.S</span>
          </h2>
          <p className="mt-4 text-lg py-6 leading-6 text-gray-600">
            <p>NIT. 900.868.271-1</p>
            <p>CUENTA AHORROS BBVA COLOMBIA:<p>
              <p>9 dígitos: 401004007</p>
            </p>10 dígitos: 04010004007</p>
            <p>16 dígitos: 0401000200004007</p>
          </p>
        </div>
        :
        <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:py-10 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold py-4 sm:text-4xl">
            <span className="block">DECOAM SAS</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-600">
            <p>NIT. 901.116.008-6</p>
            <p>CUENTA AHORROS BBVA COLOMBIA:<p>
              <p>9 dígitos: 401004460</p>
            </p>10 dígitos: 0401004460</p>
            <p>16 dígitos: 0401000200004460</p>
          </p>
        </div>
      }
      <div className="flex sticky bottom-5 md:bottom-10">
        <LargeButton onClick={() => history.push(`${routes.transaction}/${transaction?._id}`)}>Ver mi transacción</LargeButton>
      </div>

    </div >
  )

}

export default PaymentInstructions
