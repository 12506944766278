import React, { useEffect, useState } from 'react'
import jwt from "jsonwebtoken"
import Token from "types/Token"


const SessionContext = React.createContext<[string | undefined | null, Function]>([undefined, () => false])

interface SessionContextProviderProps {
  children: React.ReactNode
}
export const TOKEN_STORAGE_KEY = "token"

export const SessionContextProvider = ({ children }: SessionContextProviderProps) => {

  const retreiveSessionFromStorage = (): string | null => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY)
    if (!token) return null
    const decoded = jwt.decode(token) as Token
    if (Date.now() >= decoded.exp * 1000) return null
    return token
  }

  const [token, setToken] = useState<string | undefined | null>(retreiveSessionFromStorage)


  useEffect(() => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY)
    if (token) {
      setToken(token)
      return
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const urlToken = params.get('token');
    if (urlToken) {
      setToken(urlToken)
      return
    }
  }, [])

  useEffect(() => {
    if (token) {
      console.log("Saving token in local storage")
      localStorage.setItem(TOKEN_STORAGE_KEY, token)
    } else {
      console.log("removing token from local storage")
      localStorage.removeItem(TOKEN_STORAGE_KEY)
    }

  }, [token])
  return (
    <SessionContext.Provider value={[token, setToken]}>
      { children}
    </SessionContext.Provider>
  )

}

export default SessionContext
