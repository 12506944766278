
import Rules from "pages/Buy/components/Rules"
import Proposal from "pages/Buy/components/Proposal"
import AcceptOffer from "pages/Buy/components/AcceptOffer"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import routes from '../../config/routes'
import useSession from "hooks/useSession";
import FormStep from "components/Form/FormStep"
import ShareValue from "types/ShareValue"
import useBuyShares from "hooks/useBuyShares"
import LargeButton from "components/LargeButton"
import PaymentInstructions from "./components/PaymentInstructions"
import Spinner from "components/Spinner/Spinner"


export interface ShareRequest {
  amount: number
  shareValue: ShareValue
}


const Buy = () => {
  const history = useHistory()

  const { currentUser } = useSession()

  const { simulation, isLoadingSimulation, buy, transaction, isSuccess } = useBuyShares(currentUser?._id)

  // Redirect if no buying period is active
  useEffect(() => {
    if (isLoadingSimulation) return
    if (!simulation?.isValidBuyingDate) {
      history.push(routes.invalidDate)
    }
  }, [simulation])

  const [isRulesStep, setIsRulesStep] = useState(true)
  const [isProposalStep, setIsProposalStep] = useState(false)
  const [isAcceptOfferStep, setIsAcceptOfferStep] = useState(false)

  const [amount, setAmount] = useState<number | undefined>()

  const handleRulesAccept = (_e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    setIsRulesStep((prev) => false)
    setIsProposalStep((prev) => true)
  }

  const handleBuyShares = async () => {
    if (!amount) return
    try {
      await buy(amount)
    } catch (err) {
      alert("algo salió mal")
    }
  }

  if (isLoadingSimulation) return <div className="absolute inset-0 flex items-center justify-center h-full"><Spinner size="extra-large" /></div>

  if (!simulation) return <h1>...cargando información</h1>

  if (simulation.hasAlreadyBought) return (

    <FormStep
      title="Oups... Ya no puedes comprar acciones"
      subtitle="Ya tienes una transacción pendiente o aceptada para este periodo de compra.">
      <div className="text-gray-800 ">
        <p>Si todavía no pagaste tus acciones, revisa el monto a pagar en el detalle de la transacción y realiza el pago.</p>
        <p className="mt-2 font-medium"> Una vez el pago realizado, debes subir el comprobante de la transferencia bancaria.</p>
      </div>
      <div className="flex">
        <LargeButton onClick={() => history.push(routes.home)}>Volver a mis transacciones</LargeButton>
      </div>
    </FormStep>
  )

  if (!simulation.anualSalary || simulation.anualSalary === 0) return (
    <FormStep
      title="Oups... Nos falta información para que puedas comprar acciones"
      subtitle="No tenemos información sobre tu salario, comunícate con recursos humanos para que actualicen esta información.">
      <div className="flex">
        <LargeButton onClick={() => history.push(routes.home)}>Volver a mis transacciones</LargeButton>
      </div>
    </FormStep>
  )


  return (
    <div className="">
      <FormStep
        title="Reglas y Regulaciones"
        subtitle="Aceptas las reglas y regulaciones del plan de participación accionaria de decathlon?"
        current={isRulesStep}
        onBack={() => history.push(routes.home)}>
        <Rules
          onClick={handleRulesAccept}
        />
      </FormStep>
      <FormStep
        title="Cuantas acciones deseas comprar?"
        subtitle="Puedes comprar máximo el 25% de tu salario bruto anual"
        current={isProposalStep}
        onBack={
          () => {
            setIsRulesStep(true)
            setIsProposalStep(false)
          }
        }>
        <Proposal
          onChooseAmount={(amount) => {
            setAmount(amount)
            setIsProposalStep(false)
            setIsAcceptOfferStep(true)
          }}
          simulation={simulation}
          amount={amount}
        />
      </FormStep>
      <FormStep
        title="Acepta la oferta"
        subtitle="Ahora debes aceptar la oferta y condiciones para la compra de acciones"
        current={isAcceptOfferStep && !isSuccess}
        onBack={
          () => {
            setIsProposalStep(true)
            setIsAcceptOfferStep(false)
          }
        }>
        <AcceptOffer
          amount={amount!}
          simulation={simulation}
          onAcceptOffer={handleBuyShares}
        />
      </FormStep>
      <FormStep
        title="Un ultimo paso..."
        subtitle="Tu transacción ha sido recibida, no olvides realizar el pago de tu acciones"
        current={isSuccess}>
        <PaymentInstructions transaction={transaction} />
      </FormStep>
    </div>

  )
}

export default Buy





