import Button from "components/Button"
import FormStep from "components/Form/FormStep"
import routes from "config/routes"
import useSession from "hooks/useSession"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { formatNumberToEuroString } from "utils"
import SellMotiveSelection from "./components/SellMotiveSelection"
import SellAmountSelection from "./components/ShareAmountSelection"
import SellTypeSelection from "./components/SellTypeSelection"
import EventDateSelection from "./components/EventDateSelection"
import LargeButton from "components/LargeButton"
import useSellShare from "hooks/useSellShares"
import Spinner from "components/Spinner/Spinner"
import sellTypes from "config/sellTypes"
import sellMotives from "config/sellMotives"


const SellingForm = () => {
  const today = new Date()
  const [selectedSellMotiveId, setSelectedSellMotiveId] = useState<string | undefined>()
  const [requiredDocumentsSell, setRequiredDocumentsSell] = useState<string | undefined>()
  const [selectedSellTypeId, setSelectedSellTypeId] = useState<string | undefined>()
  const [eventDate, setEventDate] = useState<Date | undefined>()
  const [isTotalSell, setIsTotalSell] = useState<boolean | undefined>(false)
  const [amount, setAmount] = useState<number | undefined>()

  const history = useHistory()
  const { currentUser } = useSession()

  const { sell, simulation, isLoadingSimulation, error, isSuccess, transaction, isLoading } = useSellShare(currentUser?._id)

  useEffect(() => {
    if (!error) return
    // handle error
  }, [error])

  // const handleCreateTransaction = () => (amount && selectedSellMotiveId) && sell(amount, selectedSellMotiveId, eventDate)

  const handleCreateTransaction = () => {
    (amount && selectedSellMotiveId) && sell(amount, selectedSellMotiveId, eventDate)
    console.log(transaction);
  }

  const handleCreateUnlockedTransaction = () => (amount) && sell(amount, sellMotives.UNLOCKED, today)

  if (isLoadingSimulation) return <div className="absolute inset-0 flex items-center justify-center h-full"><Spinner size="extra-large" /></div>

  return (
    <div className="">
      <FormStep
        title="Qué tipo de venta deseas realizar?"
        subtitle="Puede haber venta anticipada de tus acciones o venta de acciones desbloqueadas, las cuales son acciones adquiridas por ti que ya cumplieron el periodo mínimo de 5 años desde su obtención"
        current={!selectedSellTypeId}
        onBack={() => history.push(routes.home)}>
        <SellTypeSelection
          onSelectSellType={setSelectedSellTypeId}
          unlockedSharesAmount={simulation?.unlockedAmount}
          lockedSharesAmount={simulation?.lockedSharesAmount}
        />
      </FormStep>


      <FormStep
        title="Por que causal deseas vender tus acciones anticipadamente?"
        subtitle="Recuerda que solo puedes vender tus acciones anticipadamente por una de las siguiente causales."
        current={!!selectedSellTypeId && !selectedSellMotiveId && selectedSellTypeId === sellTypes.ANTICIPATED}
        onBack={() => setSelectedSellTypeId(undefined)}>
        <SellMotiveSelection
          onSelectSellMotive={setSelectedSellMotiveId}
          enableTotalSell={setIsTotalSell}
          requiredDocuments={setRequiredDocumentsSell} />
      </FormStep>


      <FormStep
        title="Cuál es la fecha del evento?"
        subtitle="Debes ingresar la fecha en la cual se llevó a cabo el evento asociado al motivo de esta venta"
        current={!!selectedSellMotiveId && !eventDate}
        onBack={() => setSelectedSellMotiveId(undefined)}>
        <EventDateSelection
          onSelectEventDate={setEventDate} />

      </FormStep>

      <FormStep
        title="Cuántas acciones deseas vender?"
        subtitle="Después de un periodo de 5 años desde la obtención de estas acciones puedes solicitar su venta libremente, deseas continuar?"
        current={selectedSellTypeId === sellTypes.UNLOCKED && !amount}
        onBack={() => setSelectedSellTypeId(undefined)}>
        <SellAmountSelection
          onChooseAmount={setAmount}
          shareValue={simulation?.shareValue}
          amount={amount}
          sellType={selectedSellTypeId}
          isTotalSell={isTotalSell} />
      </FormStep>

      <FormStep
        title="Cuántas acciones deseas vender ?"
        subtitle="Algunos causales de venta imponen vender todas tus acciones"
        current={!!eventDate && !amount}
        onBack={() => {
          setEventDate(undefined)
        }}>
        <SellAmountSelection
          onChooseAmount={setAmount}
          shareValue={simulation?.shareValue}
          amount={amount}
          sellType={selectedSellTypeId}
          isTotalSell={isTotalSell} />
      </FormStep>

      <FormStep
        title="Resumen de la venta"
        current={!!amount && !isSuccess}
        onBack={() => {
          setAmount(undefined)
        }}>
        <div className="flex flex-col">

          <p className="mt-4 text-sm text-justify text-gray-500 leading-6">
            De conformidad con los artículos de asociación y la regulación interna del PLAN DE PARTICIPACIÓN ACCIONARIA DE DECATHLON SCA,
            el suscrito OFERENTE, ofrece la cantidad de acciones DISP indicada a continuación a WEDDELL S.A. El OFERENTE se compromete a
            cumplir las obligaciones tributarias que puedan surgir para él por causa de esta venta. El pago del monto de los Instrumentos
            DISP a favor del OFERENTE constituye aceptación por parte de WEDDELL S.A. de la presente oferta y constituye título para la
            transferencia de la propiedad de los instrumentos DISP.
            De igual forma, con la aceptación de este texto, WEDDELL S.A., DISP SCA o cualquier otra sociedad designada por ésta,
            está autorizada para cancelar y/o modificar la inscripción del OFERENTE en los registros pertinentes, como consecuencia de la
            transferencia de los Instrumentos DISP.
          </p>
          <p className="mt-4 text-sm text-justify text-gray-500 leading-6">
            Solicitas vender <span className="font-bold">{amount} acciones. </span>
            Al día de hoy estas acciones tienen un valor total de <span className="font-bold"> {formatNumberToEuroString((amount ?? 0) * (simulation?.shareValue?.valueEuro ?? 0))}</span>

          </p>
          <div className="flex sticky bottom-5 md:bottom-10">
            {selectedSellTypeId === sellTypes.ANTICIPATED &&
              <LargeButton onClick={handleCreateTransaction} isLoading={isLoading}>Vender {amount} acciones</LargeButton>
            }
            {selectedSellTypeId === sellTypes.UNLOCKED &&
              <LargeButton onClick={handleCreateUnlockedTransaction} isLoading={isLoading}>Vender {amount} acciones</LargeButton>
            }
          </div>
        </div>
      </FormStep>

      {selectedSellTypeId === sellTypes.ANTICIPATED &&
        < FormStep
          title="Tu solicitud ha sido creada !"
          subtitle="Empezaremos a tratar tu solicitud cuando subas los documentos requeridos"
          current={isSuccess}>
          <div className="flex flex-col">
            <div className="flex-1 bg-white rounded-lg p-4 shadow-md ">
              <h3 className="font-medium">No olvides subir los siguientes documentos:</h3>
              <ul className="list-disc list-inside w-1/2">
                <li className="my-3 text-gray-600">{requiredDocumentsSell}</li>
              </ul>
            </div>
            <LargeButton onClick={() => history.push(`${routes.transaction}/${transaction?._id}`)}>Ir a la transaction y subir los documentos</LargeButton>
          </div>
        </FormStep>
      }
      {selectedSellTypeId === sellTypes.UNLOCKED &&
        < FormStep
          title="Tu solicitud ha sido creada !"
          subtitle="Revisaremos tu solicitud para hacer efectiva la venta de tus acciones"
          current={isSuccess}>
          <div className="flex flex-col">
            <LargeButton onClick={() => history.push(`${routes.transaction}/${transaction?._id}`)}>Ir a la transaction</LargeButton>
          </div>
        </FormStep>
      }
    </div >
  )
  //
}

export default SellingForm
