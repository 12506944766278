import clsx from "clsx"
import transactionStates from "config/transactionStates"
import { useTranslation } from "react-i18next"

interface TransactionStateBadgeProps {
  state: transactionStates,
  hideOnFinished?: boolean
}

const TransactionStateBadge = ({ state, hideOnFinished = true }: TransactionStateBadgeProps) => {

  const { t } = useTranslation()

  const stateLabelClassnames = clsx(
    "text-xs py-1 px-4 rounded-full font-medium truncate",
    (state === transactionStates.PENDING_ADMIN || state === transactionStates.ACCEPTED_LOCALLY) && "text-green-800 bg-green-100",
    (state === transactionStates.PENDING_SHAREHOLDER || state === transactionStates.PENDING_PAYMENT) && "text-red-800 bg-red-100",
    (state === transactionStates.ACCEPTED || state === transactionStates.REJECTED || state === transactionStates.CANCELLED) && hideOnFinished ? "hidden" : "text-green-800 bg-green-100"
  )

  return <div className="flex truncate"><p className={stateLabelClassnames}> {t(`transaction_states.${state}`)}</p></div>

}

export default TransactionStateBadge
