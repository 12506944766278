import clsx from "clsx"
import LargeButton from "components/LargeButton"
import { useEffect } from 'react'
import { useForm } from "react-hook-form"
import { formatNumberToEuroString, formatNumberToPesoString } from "utils"
import { BuyingSimulation } from "hooks/useBuyShares"

export interface SharesNumberFormsimulation {
  currentShareValue: number
  sharesNumber: number
}

interface ProposalProps {
  simulation: BuyingSimulation
  amount: number | undefined
  onChooseAmount: (amount: number) => void
}

const Proposal = ({ simulation, onChooseAmount, amount }: ProposalProps) => {

  // Refactoring to react-hook-form ? => see AmountSelection in Selling form

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  })

  useEffect(() => {
    setValue("amount", amount)
  }, [amount])

  const inputClassNames = clsx(
    "w-full text-4xl font-semibold focus:outline-none focus:ring-0 border-none focus:border-none bg-transparent rounded-lg p-0",
    errors.amount ? "text-red-500" : "text-green-500"
  )

  const onSubmit = (data: any) => onChooseAmount(data.amount)

  return (
    <div className="py-5 sm:p-0">
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-1 sm:gap-4">
        <p className="text-gray-600">Con un salario bruto anual de: <span className="font-semibold">{simulation && formatNumberToPesoString(simulation.anualSalary)}</span></p>
        <p className="text-gray-600">Puedes invertir un total de: <span className="font-semibold">{simulation && formatNumberToPesoString(simulation.maxAmount * simulation.shareValue.valueCOP)}</span> </p>
        <p className="text-gray-600">Lo cual equivale a una cantidad de: <span className="font-semibold">{simulation.maxAmount}  acciones </span></p>
      </div>
      <p className="text-base font-semibold text-gray-600 py-4">
        Cuantas acciones desearías adquirir?
      </p>
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="">
            <input
              ref={register({
                required: true,
                max: simulation.maxAmount,
                min: 1
              })}
              name="amount"
              placeholder="0"
              className={inputClassNames} type="number"
            />
            <p className={`${errors.amount ? "text-red-500" : "text-gray-400"} `}>Máximo {simulation.maxAmount} acciones</p>
          </div>
          <div>
            <p className="md:text-right mt-4 md:mt-0 md:pl-4 text-4xl text-gray-400 font-semibold focus:outline-none focus:ring-0 border-none focus:border-none bg-transparent rounded-lg p-0">
              {formatNumberToEuroString((watch("amount") ?? 0) * (simulation?.shareValue.valueEuro ?? 0))}
            </p>
            <p className={`md:text-right text-gray-400 `}>{formatNumberToPesoString((watch("amount") ?? 0) * (simulation?.shareValue.valueCOP ?? 0))} estimados</p>
          </div>
          <div className="py-4">
            <p className="text-base font-semibold text-gray-600 py-2">Valor actual de la acción:</p>
            <p className="mt-1 text-4xl py-2 text-gray-400">
              {formatNumberToEuroString(simulation?.shareValue.valueEuro)}
            </p>
            <p className={`md:text-left text-gray-400 `}>{formatNumberToPesoString((simulation?.shareValue.valueCOP ?? 0))} estimados</p>
            <p className={`md:text-left text-xs text-gray-400 `}>*valor de referencia sujeto a la tasa de cambio</p>
          </div>
        </div>
        <div className="flex sticky bottom-5 md:bottom-10">
          <LargeButton disabled={errors.amount || !watch("amount")}>Seguir</LargeButton>
        </div>
      </form >
    </div>

  )

}

export default Proposal

