import Input from "components/Input";
import useSession from "hooks/useSession";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button";

export interface LoginFormData {
  email: string;
  password: string;
}

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
  });

  const { loginWithSSO, login, isLoggingIn } = useSession();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: LoginFormData) => login(data.email, data.password);

  return (
    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 w-full">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Ingresa a tu cuenta
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <div>
              <div className="mt-1">
                <Input
                  ref={register({
                    required: "Por favor ingresar un correo electronico",
                  })}
                  error={errors.email?.message}
                  label="Correo electronico"
                  name="email"
                  type="email"
                  autoComplete="email"
                />
              </div>
            </div>
            <div>
              <Input
                ref={register({ required: "Por favor ingresar tu contraseña" })}
                error={errors.email?.password}
                label="Contraseña"
                name="password"
                type="password"
                autoComplete="password"
              />
            </div>
            <div>
              <Button
                variant="primary"
                type="submit"
                size="large"
                isLoading={isLoggingIn}
              >
                Ingresar
              </Button>
            </div> */}
          </form>
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">O</span>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-3">
              <div>
                <Button
                  variant="primary"
                  isLoading={isLoading}
                  size="large"
                  onClick={() => {
                    setIsLoading(true);
                    loginWithSSO();
                  }}
                >
                  Conéctate con tu cuenta Decathlon
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginForm;
