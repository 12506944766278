
import LargeButton from "components/LargeButton"
import { formatNumberToEuroString } from "utils"
import { BuyingSimulation } from 'hooks/useBuyShares'

interface AcceptOfferProps {
  amount: number
  simulation: BuyingSimulation
  onAcceptOffer: () => void
}

const AcceptOffer = ({ amount, simulation, onAcceptOffer }: AcceptOfferProps) => {

  return (
    <div className="">
      <div className="bg-transparent sm:rounded-lg">
        <div className="max-w-2xl mx-auto">
          <div className="max-w-5xl mx-auto">
            <dl className="rounded-lg  bg-white shadow-lg sm:grid sm:grid-cols-3">
              <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                <dt className="order-2 mt-2 text-md leading-6 font-medium text-gray-500">
                  Acciones propuestas
              </dt>
                <dd className="order-1 text-xl font-extrabold text-blue-600">
                  {amount}
                </dd>
              </div>
              <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt className="order-2 mt-2 text-md leading-6 font-medium text-gray-500">
                  Valor Acción
              </dt>
                <dd className="order-1 text-xl font-extrabold text-blue-600">
                  {formatNumberToEuroString(simulation.shareValue.valueEuro)}
                </dd>
              </div>
              <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                <dt className="order-2 mt-2 text-md leading-6 font-medium text-gray-500">
                  Valor Total
              </dt>
                <dd className="order-1 text-xl font-extrabold text-blue-600">

                  {formatNumberToEuroString(simulation.shareValue.valueEuro * amount)}
                </dd>
              </div>
            </dl>
          </div>

          <p className="mt-8 text-sm text-justify text-gray-500 leading-6">
            Acepto íntegramente las condiciones de la oferta de instrumentos DISP realizada por WEDDELL S.A., sociedad
            bajo el régimen legal Belga. Adicionalmente, manifiesto haber leído y comprendido la totalidad de las
            disposiciones de la oferta, incluidas las condiciones establecidas en las Reglas y regulaciones del plan de
            participación accionaria de Decathlon International SCA. y autorizo a DISP o a la entidad autorizada por esa
            entidad para que realice los registros necesarios para la inscripción de los instrumentos DISP a mí nombre.
          </p>
          <div className="flex sticky bottom-5 md:bottom-10">
            <LargeButton onClick={onAcceptOffer}>Acepto</LargeButton>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AcceptOffer


