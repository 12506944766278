import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { Transaction } from '../../types'
import DocumentUpload from './components/DocumentUpload'
import axiosApiInstance from '../../utils/Axios'
import transactionStates from 'config/transactionStates'
import DocumentList from './components/DocumentList'
import { formatDateToDateString, formatNumberToEuroString, formatNumberToPesoString } from 'utils'
import useBlurSensitiveData from 'hooks/useBlurSensitiveData'
import TransactionTimeline from './components/TransactionTimeline/TransactionTimeline'
import Card from 'components/Card/Card'
import PageHeader from 'components/PageHeader'
import routes from 'config/routes'
import UploadPowerAlert from 'components/UploadPowerAlert'
import { ExclamationIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import TransactionStateBadge from 'pages/Home/components/TransactionList/TransactionStateBadge'

interface TransactionDetailsRouteParams {
  id: string
}

export function TransactionDetails() {
  const { shouldBlur } = useBlurSensitiveData()

  const history = useHistory()

  const { t } = useTranslation()

  const { id } = useParams<TransactionDetailsRouteParams>();
  const {
    data: transaction,
  } = useQuery([id], () => axiosApiInstance.get<Transaction>(`/transactions/${id}`).then(res => res.data))

  return (
    <div className="flex lg:max-w-7xl lg:mx-auto mx-4 h-full">
      <div className="max-w-4xl flex flex-col md:flex-row items-start lg:ml-14">
        <div className="hidden lg:block md:sticky md:top-16">
          <button
            onClick={() => history.goBack()}
            className="rounded-2xl md:border border-gray-300 md:p-3 flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <svg className="h-6 w-6 md:h-8 md:w-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        </div>
        <div className="lg:ml-24 mx-4">
          <div className="grid gap-4">
            <PageHeader title={`Detalle de la transacción ${transaction?._id}`} breadcrumb={[{ label: "Transacciones", path: routes.home }]} />
            <UploadPowerAlert />
            {
              (transaction?.state == transactionStates.PENDING_SHAREHOLDER || transaction?.state == transactionStates.PENDING_PAYMENT) && (
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-yellow-700">
                        Acción necesaria, sube los documentos requeridos o revisa el historial de la transacción
              </p>
                    </div>
                  </div>
                </div>
              )
            }

            <Card title="Información de la Transacción" subtitle="Detalles y estatus">
              <div className="">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Tipo de transacción
                  </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {transaction && t(`transaction_types.${transaction.type}`)}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Fecha de la transacción
                  </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {transaction && formatDateToDateString(transaction.createdAt)}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Estado actual
            </dt>
                    <dd className="flex">
                      {transaction && <TransactionStateBadge state={transaction.state} hideOnFinished={false} />}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Número de Acciones
            </dt>
                    <dd className={`mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ${shouldBlur && "blurred"}`}>
                      {transaction && transaction.amount}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Costo total de las acciones
                    </dt>
                    <dd className={`mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ${shouldBlur && "blurred"}`}>
                      {transaction &&
                        <span>
                          {formatNumberToEuroString(transaction.amountEuro)} &bull; {formatNumberToPesoString(transaction.amountCOP)}
                        </span>
                      }
                      <p className="mt-2 md:text-left text-xs text-gray-400">*El valor en pesos anunciado es un estimado. Al momento de la venta se tendrá en cuenta la tasa de cambio ofrecida por el banco para la compra de las divisas</p>
                    </dd>

                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Valor de la acción cuando se realizó la transacción
            </dt>
                    <dd className={`mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ${shouldBlur && "blurred"}`}>
                      {transaction && <span>
                        {formatNumberToEuroString(transaction.shareValue.valueEuro)} &bull; {formatNumberToPesoString(transaction.shareValue.valueCOP)}
                      </span>
                      }
                    </dd>
                  </div>

                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Documentos Adicionales
            </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-y-6">
                      <DocumentList documents={transaction?.documents} />
                      {
                        (transaction?.state === transactionStates.PENDING_ADMIN || transaction?.state === transactionStates.PENDING_SHAREHOLDER || transaction?.state === transactionStates.PENDING_PAYMENT) &&
                        <DocumentUpload transactionId={id} />
                      }
                    </dd>
                  </div>
                </dl >
              </div>
            </Card>
            <Card title="Historial de la Transacción">
              <TransactionTimeline events={transaction?.stateUpdates} />
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}



