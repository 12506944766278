import TransactionStateUpdate from "types/TransactionStateUpdate"
import TimelineEvent from "./TimelineEvent"

interface TransactionTimelineProps {
  events: Array<TransactionStateUpdate> | undefined
}

const TransactionTimeline = ({ events }: TransactionTimelineProps) => {

  return (
    <ul>
      {
        events?.map((event, index) => <TimelineEvent key={event._id} event={event} isLast={index === events.length - 1} />)
      }
    </ul>
  )

}

export default TransactionTimeline
