enum routes {
  login = "/login",
  home = "/",
  buy = "/buy",
  out = "/out",
  sell = "/sell",
  about = "/about",
  transaction = "/transaction",
  profile = "/profile",
  invalidDate = "/invalid-buying-period",
  bae = "/bae"

}

export default routes
