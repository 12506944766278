import Document from "types/Document"
import DocumentListItem from "./DocumentListItem"

interface DocumentListProps {
  documents: Array<Document> | undefined
}

const DocumentList = ({ documents }: DocumentListProps) => {

  return (
    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
      {
        documents && documents.length > 0 ?
          documents.map(document => <DocumentListItem key={document._id} document={document} />)
          : <p className="text-center p-4 text-gray-500">No hay documentos</p>
      }
    </ul>
  )

}

export default DocumentList
