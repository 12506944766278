import { useForm } from 'react-hook-form'
import Button from '../../../components/Button'
import useUploadDocumentForTransaction from 'hooks/useUploadDocumentForTransaction'
import { notifySuccess } from 'components/ToastNotify'


export interface DocumentFormData {
  files: FileList
  documentTypeId: string
}

interface DocumentUploadProps {
  transactionId: string

}

const DocumentUpload = ({ transactionId }: DocumentUploadProps) => {

  const { documentTypes, uploadDocument, isUploading, error, isSuccess } = useUploadDocumentForTransaction(transactionId)

  const { register, handleSubmit, errors } = useForm<FormData>()


  const onSubmit = async (formData: DocumentFormData) => {
    try {
      uploadDocument(formData.files[0], formData.documentTypeId)
      notifySuccess("El documento se ha cargado")
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <form
      className="flex flex-col space-y-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex space-x-2 items-center">
        <svg className="h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
        <h3 className="text-gray-700 font-medium">Adjuntar un documento</h3>
      </div>

      <div className="flex flex-col">
        <label htmlFor="documentTypeId" className="text-sm font-medium text-gray-700">Tipo De Documento</label>
        <select
          ref={register({ required: true })}
          id="documentTypeId"
          name="documentTypeId"
          className="mt-1 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
        >
          {documentTypes && documentTypes.map((documentType) => <option key={documentType._id} value={documentType._id}>{documentType.name}</option>)}
        </select>
      </div>
      <div className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md shadow-sm">
        <svg className="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
        </svg>
        <input
          ref={register({ required: true })}
          name="files"
          type="file"
        />
      </div>
      <div className="flex justify-end">
        <Button size="small" type="submit" isLoading={isUploading}>Adjuntar</Button>
      </div>
    </form >
  )
}

export default DocumentUpload
