import queryKeys from "config/queryKeys"
import { useMutation, useQuery, useQueryClient } from "react-query"
import axiosApiInstance from "utils/Axios"
import useSession from "./useSession"

const useBAE = () => {

  const queryClient = useQueryClient()

  const { currentUser } = useSession()
  const {
    data: bae,
    isLoading: isLoadingBAE
  } = useQuery(queryKeys.BAE, () => axiosApiInstance.get(`/shareholders/${currentUser?._id}/bae`).then(res => res.data), { enabled: !!currentUser })


  const { mutate, isLoading: isAcceptingBAE, isSuccess: BAEAccepted } = useMutation(() => axiosApiInstance.post(`/bae/${bae._id}/accept`), {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.BAE)
  })

  const acceptBAE = () => mutate()

  return { bae, isLoadingBAE, acceptBAE, isAcceptingBAE, BAEAccepted }

}

export default useBAE
