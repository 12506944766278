import './App.css';
import { BrowserRouter as Router, } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from "react-query";

import { SessionContextProvider } from 'contexts/SessionContext'
import AppRouter from 'components/Routing/AppRouter'

import { BlurSensitiveDataContextProvider } from 'contexts/BlurSensitiveDataContext';
import './i18n';
import { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();

function App() {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BlurSensitiveDataContextProvider>
        <QueryClientProvider client={queryClient}>
          <SessionContextProvider>
            <Router>
              <AppRouter />
              <Toaster />
            </Router>
          </SessionContextProvider>
        </QueryClientProvider>
      </BlurSensitiveDataContextProvider>
    </Suspense>
  );

}

export default App;

